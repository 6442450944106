import config from 'app/config';
import axiosInstance from 'app/interceptors';
import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': true,
};

export const getList = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/call-for-proposal/list`,
    { params, headers }
  );
};

export const getDetailProposalReview = (id, params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/call-for-proposal/detail`,
    {
      params: { cfp_id: id },
      headers
    }
  );
};

export const getDetailProposal = (id, params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/call-for-proposal/${id}/detail`,
    { params, headers }
  );
};

export const postFinalReview = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/call-for-proposal/store/final-review`,
    params,
    { headers }
  );
};

export const postFilterNegativeList = (payload) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/call-for-proposal/safeguard/second-step`,
    payload,
    { headers }
  );
};

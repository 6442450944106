/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { isEmpty } from "lodash";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "ngrok-skip-browser-warning": true,
    common: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  },
});

const generateErrorMessage = (error) => {
  let message = "Something went wrong";
  if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
  } else if (error?.response?.data?.error?.message) {
    message = error?.response?.error?.message;
  } else if (error?.data?.message) {
    message = error?.data?.message;
  } else if (error?.data?.error?.message) {
    message = error?.data?.error?.message;
  } else if (error?.response?.data?.[0]?.message) {
    message = error?.response?.data?.[0]?.message;
  }
  return message;
};

const initAxiosInterceptors = () => {
  const requestInterceptor = (config) => {
    return config;
  };

  const responseInterceptor = (response) => {
    if ("error" in response?.data && !isEmpty(response?.data?.error)) {
      return Promise.reject(response);
    }
    return response;
  };

  const errorInterceptor = (error) => {
    if (axios.isCancel(error)) {
      return;
    }
    if (
      error?.response?.data?.error?.name === "JWSInvalid" ||
      error?.response?.data?.error?.name === "JWTExpired" ||
      error?.response?.data?.message?.includes("claim timestamp") ||
      error?.response?.status === 401
    ) {
      localStorage.clear();
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.response.use(
    responseInterceptor,
    errorInterceptor
  );
  axiosInstance.interceptors.request.use(requestInterceptor);
};

initAxiosInterceptors();

const AxiosInterceptorWrapper = ({ children }) => {
  // const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   const requestInterceptor = (config) => {
  //     return config;
  //   };

  //   const responseInterceptor = (response) => {
  //     if ("error" in response?.data && !isEmpty(response?.data?.error)) {
  //       enqueueSnackbar(generateErrorMessage(response), {
  //         autoHideDuration: 2500,
  //         variant: "error",
  //       });
  //       return Promise.reject(response);
  //     }
  //     return response;
  //   };

  //   const errorInterceptor = (error) => {
  //     if (axios.isCancel(error)) {
  //       return;
  //     }
  //     if (
  //       error?.response?.data?.error?.name === "JWSInvalid" ||
  //       error?.response?.data?.error?.name === "JWTExpired" ||
  //       error?.response?.data?.message?.includes("claim timestamp")
  //     ) {
  //       localStorage.clear();
  //       window.location.href = "/logout";
  //     }
  //     enqueueSnackbar(generateErrorMessage(error), {
  //       autoHideDuration: 2500,
  //       variant: "error",
  //     });
  //     return Promise.reject(error);
  //   };

  //   const resInterceptor = axiosInstance.interceptors.response.use(
  //     responseInterceptor,
  //     errorInterceptor
  //   );
  //   const reqInterceptor =
  //     axiosInstance.interceptors.request.use(requestInterceptor);

  //   return () => {
  //     axiosInstance.interceptors.response.eject(resInterceptor);
  //     axiosInstance.interceptors.request.eject(reqInterceptor);
  //   };
  // }, []);

  return children;
};

export default axiosInstance;
export { AxiosInterceptorWrapper };

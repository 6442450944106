import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import { useFormContext } from "react-hook-form";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import MapBadge from "components/molecules/Badge/MapBadge";
import InfoBadge from "components/molecules/Badge/InfoBadge";
import AtomSelect from "components/atoms/Select";
import OptionRadioBadge from "components/molecules/Badge/OptionRadioBadge";
import { changeAreaCoverage } from "helpers";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import RadioBadge from "components/molecules/Badge/RadioBadge";

const FormWorkingArea = ({
  areaCoverageFields = [],
  appendareaCoverage = () => {},
  removeareaCoverage = () => {},
  masterData = {},
  areaCoverageMasterData = [],
  setAreaCoverageMasterData = () => {},
  officeLocationMasterData = {},
  setOfficeLocationMasterData = () => {},
  optYesOrNo = [],
  provinceOptions,
  cityOptions,
  districtOptions,
  methods,
}) => {
  const navigate = useNavigate();

  const {
    control,
    setValue,
    trigger,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const [isEditVerificationWorkingArea, setIsEditVerificationWorkingArea] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);
  const toogleIsEditVerificationWorkingArea = () =>
    setIsEditVerificationWorkingArea(!isEditVerificationWorkingArea);

  return (
    <>
      <Card>
        <CardHeader>
          <div className="flex justify-center items-center">
            <div className="flex-1">Cakupan Wilayah Kerja</div>
            <div className="flex-1 text-right">
              {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" &&
                (isEdit ? (
                  <Button
                    type="button"
                    className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                    onClick={toogleIsEdit}
                  >
                    <BiPencil />
                    Simpan Perubahan
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                    onClick={toogleIsEdit}
                  >
                    <BiPencil />
                    Ubah
                  </Button>
                ))}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="flex flex-col rounded-xl border bg-white">
            <div className="flex gap-16 bg-[#077439] p-4">
              <div className="w-16 text-white font-[700]">No</div>
              <div className="flex-1 text-white font-[700]">Provinsi</div>
              <div className="flex-1 text-white font-[700]">Kab/Kota</div>
              <div className="w-16"></div>
            </div>
            {areaCoverageFields?.map((data, index) => (
              <div
                className="flex gap-16 items-center px-4 border-b py-2"
                key={data.id}
              >
                <div className="w-16">{index + 1}</div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`working_area.area_coverage.${index}.province_id`}
                    options={provinceOptions}
                    className="basic-single mt-2"
                    placeholder={"Pilih Provinsi"}
                    changeDependsDropdown={{
                      isArray: true,
                      index,
                      function: changeAreaCoverage,
                      options: areaCoverageMasterData,
                      masterData,
                      fieldChange: "kotaKabupaten",
                      setState: setAreaCoverageMasterData,
                    }}
                    disable={!isEdit}
                    returnObject={false}
                  />
                </div>
                <div className="flex-1">
                  <AtomSelect
                    controllerName={`working_area.area_coverage.${index}.city_id`}
                    options={cityOptions}
                    className="basic-single mt-2"
                    placeholder={"Pilih Kab/Kota"}
                    disable={!isEdit}
                    returnObject={false}
                  />
                </div>
              </div>
            ))}
            {/* button Verification */}
            <RadioVerificationBadge
              className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
              name={"working_area.status_approval_area_coverage"}
              nameNotes={"working_area.notes_approval_area_coverage"}
              isEditVerification={isEditVerification}
              toogleIsEditVerification={toogleIsEditVerification}
              errors={
                errors?.working_area?.status_approval_area_coverage
                  ? errors?.working_area?.status_approval_area_coverage
                  : false
              }
            />
          </div>
        </CardBody>
      </Card>
      {/* ====================== */}
      <Card>
        <CardHeader>
          <div className="flex justify-center items-center">
            <div className="flex-1">Lokasi Kantor</div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="space-y-6">
            <div>
              <QuestionBadge text="Dimana lokasi kantor pusat Anda saat ini?" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AtomSelect
                controllerName={"working_area.province_id"}
                options={provinceOptions}
                className="basic-single mt-2"
                label="Provinsi"
                required
                disable={!isEdit}
                changeDependsDropdown={{
                  isArray: false,
                  function: changeAreaCoverage,
                  options: officeLocationMasterData,
                  masterData,
                  fieldChange: "kotaKabupaten",
                  setState: setOfficeLocationMasterData,
                }}
                returnObject={
                  typeof methods.getValues("working_area.province_id") ===
                  "object"
                }
              />
              <AtomSelect
                controllerName={"working_area.city_id"}
                options={cityOptions}
                className="basic-single mt-2"
                label="Kota/Kabupaten"
                required
                disable={!isEdit}
                changeDependsDropdown={{
                  isArray: false,
                  function: changeAreaCoverage,
                  options: officeLocationMasterData,
                  masterData,
                  fieldChange: "kecamatan",
                  setState: setOfficeLocationMasterData,
                }}
                returnObject={
                  typeof methods.getValues("working_area.city_id") === "object"
                }
              />
              <AtomSelect
                controllerName={"working_area.district_id"}
                options={districtOptions}
                className="basic-single mt-2"
                label="Kecamatan"
                required
                disable={!isEdit}
                changeDependsDropdown={{
                  isArray: false,
                  function: changeAreaCoverage,
                  options: officeLocationMasterData,
                  masterData,
                  fieldChange: "kelurahan",
                  setState: setOfficeLocationMasterData,
                }}
                returnObject={
                  typeof methods.getValues("working_area.district_id") ===
                  "object"
                }
              />
              <AtomSelect
                controllerName={"working_area.village_id"}
                options={officeLocationMasterData?.kelurahan}
                className="basic-single mt-2"
                label="Kelurahan"
                required
                disable={!isEdit}
                changeDependsDropdown={{
                  isArray: false,
                  function: changeAreaCoverage,
                  options: officeLocationMasterData,
                  masterData,
                  fieldChange: "kodePos",
                  setState: setOfficeLocationMasterData,
                }}
                returnObject={
                  typeof methods.getValues("working_area.village_id") ===
                  "object"
                }
              />
              <AtomSelect
                controllerName={"working_area.zip_code"}
                options={officeLocationMasterData?.kodePos}
                className="basic-single mt-2"
                label="Kode Pos"
                required
                disable={!isEdit}
                returnObject={
                  typeof methods.getValues("working_area.zip_code") === "object"
                }
              />
              <InputForm
                controllerName={`working_area.address`}
                className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                label={`Alamat Lengkap`}
                placeholder={`Alamat Lengkap`}
                required
                textArea
                disabled={!isEdit}
              />
            </div>
            <div></div>
          </div>
        </CardBody>
        <div className="border-b border-[1px]" />
        <CardBody>
          <div className="space-y-6">
            <div>
              <QuestionBadge text="Apakah Anda memiliki cabang" />
            </div>
            <RadioBadge
              arrData={optYesOrNo}
              isEdit={isEdit}
              name={`working_area.hadBranch`}
              className={`flex gap-[18px] w-1/2`}
              errors={
                errors?.working_area?.hadBranch
                  ? errors?.working_area?.hadBranch
                  : false
              }
            />
            {watch("working_area.hadBranch") === "Ya" && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputForm
                  controllerName={"working_area.length_branch"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
                  label="Berapa Jumlah Cabang Yang Anda Miliki"
                  placeholder={`Input Jumlah Cabang`}
                  required
                  disabled={!isEdit}
                />
              </div>
            )}
            <div>
              <InfoBadge
                text={
                  "Data cabang Lembaga/ Instansi/ Perusahaan Anda miliki yang telah ter-upload dapat dilihat pada tab menu dokumen pendukung"
                }
              />
            </div>
          </div>
          {/* button Verification */}
          <RadioVerificationBadge
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"working_area.status_approval_working_area"}
            nameNotes={"working_area.notes_approval_working_area"}
            isEditVerification={isEditVerificationWorkingArea}
            toogleIsEditVerification={toogleIsEditVerificationWorkingArea}
            errors={
              errors?.working_area?.status_approval_working_area
                ? errors?.working_area?.status_approval_working_area
                : false
            }
          />
        </CardBody>
      </Card>
    </>
  );
};

export default FormWorkingArea;

/* eslint-disable no-useless-computed-key */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { AtomDatePicker, Button, CustomSelect, SearchInput } from 'components';
import { BasicList } from 'components/molecules/ListPage';
import { BadgeStatus as ApprovalBadgeStatus } from 'components/organisms/Approval2Level';

import useApprovalPembayaranLemtara from './hooks/useApprovalPembayaranLemtara';
import { debounce } from 'lodash';

const ApprovalPembayaranLemtara = () => {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      date_of_filling: [null, null],
    },
  });

  const [limit, setLimit] = useState(10);
  const [nameFilter, setNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [page, setPage] = useState(1);
  const [rowPage, setRowPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [sortMethod, setSortMethod] = useState('asc');

  const { watch } = methods;

  const params = {
    search: nameFilter,
    status: statusFilter,
    page: page,
    limit: limit,
    start_date: watch('date_of_filling')?.[0]
      ? moment.utc(watch('date_of_filling')[0]).local().format('YYYY-MM-DD')
      : null,
    end_date: watch('date_of_filling')?.[1]
      ? moment.utc(watch('date_of_filling')[1]).local().format('YYYY-MM-DD')
      : null,
  };

  const StatusArray = [
    {
      value: 'APPROVED',
      label: 'Disetujui',
    },
    {
      value: 'NEED_APPROVAL',
      label: 'Menunggu Persetujuan Direktur Proyek',
    },
    {
      value: 'NEED_APPROVAL_DIRECTOR',
      label: 'Menunggu Persetujuan Proyek Penyaluran Dana',
    },
    {
      value: 'REJECTED',
      label: 'Ditolak',
    },
  ];

  const {
    approvalPembayaranLemtara,
    paginationApprovalPembayaranLemtara,
  } = useApprovalPembayaranLemtara({ params });

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: '',
        sortable: true,
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(rowPage) - parseInt(rowPage) + index + 1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'project_name',
        title: 'Proyek',
        dataIndex: 'project_name',
        className: '',
        sortable: true,
        columnClassName: 'w-[180px]',
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {value}
            </div>
          );
        },
      },
      {
        id: 'lemtara.general_information.lemtara_name',
        title: 'Nama Lemtara',
        dataIndex: 'cfp',
        className: '',
        sortable: true,
        columnClassName: 'w-[180px]',
        render: (value, data) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {data?.cfp
                ? data?.cfp?.lemtara?.general_information?.lemtara_name
                : '-'}
            </div>
          );
        },
      },
      {
        id: 'address',
        title: 'Lokasi',
        dataIndex: 'address',
        className: '',
        sortable: true,
        columnClassName: 'w-[150px] text-ellipsis overflow-hidden',
        render: (value, data) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
              {data?.cfp?.lemtara
                ? `${data?.cfp?.lemtara?.working_area?.address ?? ''},
                  ${data?.cfp?.lemtara?.working_area?.village_id?.label ?? ''},
                  ${data?.cfp?.lemtara?.working_area?.district_id?.label ?? ''},
                  ${data?.cfp?.lemtara?.working_area?.city_id?.label ?? ''},
                  ${data?.cfp?.lemtara?.working_area?.province_id?.label ?? ''},
                  ${data?.cfp?.lemtara?.working_area?.zip_code?.label ?? ''}`
                : '-'}
            </div>
          );
        },
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        className: '',
        sortable: true,
        columnClassName: 'w-[150px]',
        render: (value) =>
          value === 'Menunggu Verifikasi' ? (
            value
          ) : (
            <ApprovalBadgeStatus value={value} />
          ),
      },
      {
        id: '_id',
        title: 'Aksi',
        dataIndex: '_id',
        width: 160,
        columnClassName: 'text-center w-[150px] text-ellipsis overflow-hidden',
        fixed: 'center',
        render: (value, data) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                {['NEED_APPROVAL', 'NEED_APPROVAL_DIRECTOR'].includes(
                  data.status
                ) ? (
                  <div className="space-x-3 justify-center flex w-full">
                    <Button
                      type="button"
                      className={`
                          text-white w-40
                            bg-[#00BD52]
                          `}
                      onClick={() => {
                        localStorage.setItem(
                          'withdrawalWorkPlan',
                          value.toString()
                        );
                        navigate(
                          `/approval-pembayaran-lemtara/review/${data._id}`
                        );
                      }}
                    >
                      Review
                    </Button>
                  </div>
                ) : (
                  <div className="space-x-3 flex w-full">
                    <MdOutlineRemoveRedEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={28}
                      onClick={() => {
                        localStorage.setItem(
                          'withdrawalWorkPlan',
                          value.toString()
                        );
                        navigate(
                          `/approval-pembayaran-lemtara/${data._id}/detail`
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            ''
          );
        },
      },
    ];
  }, []);

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj?.value ?? undefined);
  }, []);

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || '');
      },
      [setNameFilter]
    ),
    1000
  );

  return (
    <Fragment>
      <BasicList
        links={[
          {
            label: 'Daftar Permohonan Pembayaran Lemtara',
          },
        ]}
        title={'Daftar Permohonan Pembayaran Lemtara'}
        columns={tableColumns}
        dataSource={approvalPembayaranLemtara}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        setSortBy={setSortBy}
        className={`!w-full`}
        pagination={paginationApprovalPembayaranLemtara}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-2 flex">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
              <div className="flex-1">
                <CustomSelect
                  optionsData={StatusArray}
                  placeholder="Status"
                  className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
                  onChange={onChangeStatus}
                  isClearable={true}
                />
              </div>
            </div>
          </FormProvider>
        }
      />
    </Fragment>
  );
};

export default ApprovalPembayaranLemtara;

import React, { useCallback, useEffect, useState } from "react";
import ProgressStepForm from "components/molecules/ProgressStepForm";
import Step1 from "../addInformation";
import Step2 from "../addBenefit";
import Step3 from "../addResultChain";
import Step4 from "../addSupportFile";
import axios from "axios";
import config from "app/config";
import { Link } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";
import { useLocation, useParams } from "react-router-dom";
import { Spinner } from "components";
import {
  InvestmentPlanProvider,
  useInvestmentPlanContext,
} from "../../../context/InvestmentPlanContext";
import axiosInstance from "app/interceptors";

const DetailInvestmentPlan = () => {
  const {
    idInvestment,
    idProposal,
    currentStep,
    type,
    fetchedData,
    setData,
    setDataInvestment,
  } = useInvestmentPlanContext();
  const location = useLocation();

  let { id } = useParams();

  const fetchInvestment = async () => {
    try {
      const res = await axiosInstance.get(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan?id=${id}`
      );
      setData({
        dataInvestment: res?.data?.data,
        idInvestment: res?.data?.data?._id,
        idProposal: res?.data?.data?.step_1?.proposal_id,
        currentStep: 1,
        type: "show",
        fetchedData: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const links = [
    {
      label: "Daftar Investmen Plan",
    },
    {
      label: "Penyusunan Investment Plan",
    },
    {
      label: "Ajukan Project",
    },
    {
      label: "Ajukan Project",
    },
  ];

  const stepForm = [
    "Informasi Umum",
    "Penerima Manfaat",
    "Result Chain",
    "Dokumen Pendukung",
  ];

  useEffect(() => {
    id && fetchInvestment();
  }, [id]);

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1">
        <ul className="flex items-center">
          <li className="inline-flex items-center">
            <Link to="/">
              <RiHome6Line className="text-gray-500 hover:text-primary-700" />
            </Link>
            <FiChevronRight className="text-gray-300 mx-2 w-5" />
          </li>
          <li className="inline-flex items-center">
            <Link
              to="/investment-plan"
              className="mb-0 text-gray-600 text-sm hover:text-primary-700"
            >
              {currentStep === 1 ? (
                <div>Daftar Investment Plan</div>
              ) : currentStep === 2 ? (
                <div>Penyusunan Investment Plan</div>
              ) : (
                <div>Daftar Investment Plan</div>
              )}
              {/* {item.label} */}
            </Link>
            <p>&nbsp;{" > "}&nbsp;</p>
            <Link className="mb-0 text-gray-600 text-sm hover:text-primary-700">
              Detail Investment Plan
              {/* {item.label} */}
            </Link>
          </li>
        </ul>
      </div>
      {/* end BreadCrumb */}
      {false ? (
        <div className="flex justify-center items-center min-h-screen w-full">
          <Spinner theme={"primary"} height="h-16" width="w-16" />
        </div>
      ) : (
        <>
          <ProgressStepForm items={stepForm} step={currentStep} />
          {currentStep === 1 ? (
            <Step1 />
          ) : currentStep === 2 ? (
            <Step2 />
          ) : currentStep === 3 ? (
            <Step3 />
          ) : (
            <Step4 />
          )}
        </>
      )}
    </div>
  );
};

const DetailInvestmentPlanWithContext = () => {
  return (
    <InvestmentPlanProvider>
      <DetailInvestmentPlan />
    </InvestmentPlanProvider>
  );
};

export default DetailInvestmentPlanWithContext;

import React, { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiArrowRightLine, RiFile2Line } from 'react-icons/ri';

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputFormRadio,
} from 'components';
import { useNavigate, useParams } from 'react-router';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { getDetailProposal } from 'services/danaProgram/callForProposalService';
import { getUrlFile } from 'services/danaProgram/fileService';

import _ from 'lodash';

const ExecutorLocationDetail = ({ onNext, onPrev }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  let { id } = useParams();

  const methods = useForm({
    mode: 'onChange',
  });

  const { control } = { ...methods };

  const {
    fields: fieldsExecutorLocation,
    append: appendExecutorLocation,
    remove: removeExecutorLocation,
  } = useFieldArray({
    control,
    name: 'executorLocation',
  });

  const downloadFile = async (item) => {
    const res = await getUrlFile({
      identifier: 'id',
      value: item?.fileId,
    });
    window.open(res?.data?.data?.url, '_blank');
  };

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
    appendExecutorLocation({
      is_conservation_area: res.data.data?.is_conservation_area,
      is_allowed: res.data.data?.is_allowed,
    });
  };

  useEffect(() => {
    fetchDetailCfp({ category: 'executor-locatoion' });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      methods.reset({
        is_conservation_area: data?.is_conservation_area,
        is_allowed: data?.is_allowed,
      });
    }
  }, [data]);

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>Lokasi Pelaksanaan</div>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <div className="flex flex-col space-y-5">
              {data && (
                <CardForm label="Lokasi Pelaksanaan" buttonCollapse={true}>
                  {data?.location?.length > 0 ? (
                    data.location.map((location, index) => {
                      return (
                        <div className="flex flex-col space-y-5">
                          <span className="flex-1 font-bold">
                            Lokasi {index + 1}
                          </span>
                          <div className="flex w-full">
                            <span className="flex-1 font-bold">Provinsi</span>
                            <span className="flex-1 font-bold">Kota</span>
                            <span className="flex-1 font-bold">Kabupaten</span>
                            <span className="flex-1 font-bold">
                              Kelurahan / desa
                            </span>
                          </div>
                          <div className="flex">
                            <span className="flex-1">
                              {location.province.label}
                            </span>
                            <span className="flex-1">
                              {location.city.label}
                            </span>
                            <span className="flex-1">
                              {location.district.label}
                            </span>
                            <span className="flex-1">
                              {location.village.label}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </CardForm>
              )}

              <div className="grid gap-2">
                <span className="font-bold">
                  Peta Batasan Wilayah Pelaksanaan
                </span>
                {data?.boundary_map.length > 0 ? (
                  data?.boundary_map.map((item, index) => {
                    return (
                      <div className="border p-4 rounded-2xl">
                        <div className="flex gap-4">
                          <div>
                            <div className="bg-[#EEFFF4] rounded-full p-4">
                              <RiFile2Line className="text-primary-600" />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <span className="font-medium">{item.fileName}</span>
                            <span>{item.fileSize}</span>
                            <button
                              className="text-left"
                              onClick={() => downloadFile(item)}
                            >
                              <span className="font-semibold text-primary-600">
                                Download file
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>

              {data && (
                <CardForm
                  label="Tentang Lokasi Pelaksanaan"
                  buttonCollapse={true}
                >
                  {
                    <>
                      <div className="flex flex-col space-y-5">
                        <div className="flex flex-col">
                          <label className="text-[#1D2939] font-bold">
                            Apakah lokasi/ area pelaksanaan program/ kegiatan di
                            atas termasuk ke dalam area/ kawasan konservasi?
                          </label>
                          <InputFormRadio
                            controllerName={`is_conservation_area`}
                            className={`py-0 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                            values={[
                              { value: true, label: 'YES' },
                              { value: false, label: 'NO' },
                            ]}
                            disabled
                          />
                        </div>
                        {data?.is_conservation_area && (
                          <div className="flex flex-col">
                            <label className="text-[#1D2939] font-bold">
                              Kawasan Konservasi
                            </label>
                            <label className="text-[#1D2939] text-sm">
                              {data.conservation_area}
                            </label>
                          </div>
                        )}
                        <div className="flex flex-col">
                          <label className="text-[#1D2939] font-bold">
                            Jika lokasi pelaksanaan program/ kegiatan termasuk
                            ke dalam area/ kawasan konsercasi,Apakah area/
                            kawasan konservasi tersebut tidak dilarang dan sudah
                            memperoleh izin?
                          </label>
                          <InputFormRadio
                            controllerName={`is_allowed`}
                            className={`py-0 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                            values={[
                              { value: true, label: 'YES' },
                              { value: false, label: 'NO' },
                            ]}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </>
                  }
                </CardForm>
              )}
            </div>
          </FormProvider>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={onPrev}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => onNext()}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExecutorLocationDetail;

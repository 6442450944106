import { Breadcrumbs } from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DokumenPendukung from "./DokumenPendukung";
import PengalamanPengelolaanProgram from "./SafeguardsForm";
import { useMutation } from "@tanstack/react-query";
import {
  getSafeguards,
  safeguardsSubmit,
} from "services/danaProgram/danaProgramService";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "helpers";

const Safeguards = () => {
  const navigate = useNavigate();

  let id_safeguards = JSON.parse(sessionStorage.getItem("idData")).safe_guards;
  const [activeTab, setActiveTab] = useState(1);
  const [verificationData, setVerificationData] = useState();
  const links = [
    {
      label: "Daftar Lemtara",
      path: "/verification",
    },
    {
      label: "Detail",
      path: "/verification/detail",
    },
    {
      label: "Safeguards",
    },
  ];

  const TabList = [
    {
      label: "Dokumen Pendukung",
      id: 1,
    },
    {
      label: "Safeguards",
      id: 2,
    },
  ];

  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState();

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getSafeguards(id_safeguards);
        setData(promise.data.data);
        setIsFetching(false);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  const submitData = useMutation({
    mutationFn: (params) => {
      return safeguardsSubmit(params);
    },
    onSuccess: () => {
      window.scrollTo({ behavior: "smooth", top: 0 });
      navigate(`/verification/detail`);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const consumeAPI = (arr) => {
    let params;
    let dataSafeguards = {
      data_safeguard: [],
      dataApproval: "",
      dataNotes: "",
    };

    arr?.safeguards?.map((field, index) => {
      let temp = {
        desc: arr?.safeguards[index]?.desc,
        name: arr?.safeguards[index]?.name,
        period: arr?.safeguards[index]?.period,
        report: arr?.safeguards[index]?.report,
      };
      let tempApproval = arr?.safeguards[index]?.status_approval_safeguard_data;
      let tempNotes = arr?.safeguards[index]?.notes_approval_safeguard_data;
      dataSafeguards.data_safeguard.push(temp);
      dataSafeguards.dataNotes = tempNotes;
      dataSafeguards.dataApproval = tempApproval;
    });

    params = {
      user_id: data?.user_id,
      status: "VERIFIED",
      data_safeguard: dataSafeguards.data_safeguard,
      status_approval_safeguard_data: dataSafeguards.dataApproval,
      notes_approval_safeguard_data: dataSafeguards.dataNotes,
      safeguard_document: {
        document_support: data.safeguard_document?.document_support,
        notes_approval: verificationData?.notes_approval,
        status_approval: verificationData?.status_approval,
        _id: data.safeguard_document?._id,
        safeguard_id: data.safeguard_document?.safeguard_id,
      },
    };

    submitData.mutateAsync(params);
  };

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <div className="tabs">
        {TabList.map((data) => (
          <a
            className={`tab text-[14px] px-8 tab-bordered ${
              activeTab === data.id
                ? "border-b-primary-600 text-primary-600 bg-primary-100 font-bold"
                : ""
            } hover:text-primary-600 hover:border-primary-700`}
            onClick={() => setActiveTab(data.id)}
          >
            {data.label}
          </a>
        ))}
      </div>
      <div>
        {activeTab === 1 && (
          <DokumenPendukung
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            data={data}
            setData={setData}
            setVerificationData={setVerificationData}
          />
        )}
        {activeTab === 2 && (
          <PengalamanPengelolaanProgram
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            data={data}
            setData={setData}
            submitData={consumeAPI}
          />
        )}
      </div>
    </div>
  );
};

export default Safeguards;

import React from "react";

import { APPROVAL_STATUSSES } from "./constant";

const BadgeStatus = ({ align = "center", fit = false, value }) => {
  const status = APPROVAL_STATUSSES.find((opt) => opt.value === value);

  if (!status) return <></>;

  let className = `${status.color} rounded-xl font-semibold text-xs`;

  if (align === "left") className += " p-1 px-3 text-left";

  if (align === "center") className += " p-1 text-center";

  className += ` ${fit ? "w-fit" : "w-full"}`;

  return (
    <div className={className} title={status.label}>
      {status.label}
    </div>
  );
};

export default BadgeStatus;

import {
  AtomDatePicker,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormRadio,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const PengalamanPengelolaanProgramChild = ({ experience, data, methods }) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid }
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  useEffect(() => {
    for (let key in data) {
      if (key.includes("approval")) {
        methods.setValue(key, data[key]);
      }
    }
  }, [data]);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Peralatan/ Perlengkapan</div>
          <div className="flex-1 text-right">
            {isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge text="Kegiatan apa saja yang sudah lembaga/ institusi/ perusahaan Anda lakukan terkait perlindungan dan pengelolaan lingkungan hidup selama 5 tahun terakhir?" />
          {experience?.map((field, index) => {
            return (
              <div className="mt-[20px]" key={field.id}>
                <div className="space-y-6">
                  <InputForm
                    controllerName={`experience.${index}.name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Nama Program"}
                    placeholder={"Nama Program"}
                    disabled={!isEdit}
                  />
                  <InputForm
                    controllerName={`experience.${index}.program_relevance`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Relevansi dengan program BPDLH"}
                    placeholder={"Relevansi dengan program BPDLH"}
                    disabled={!isEdit}
                    customValue={field.program_relevance?.label}
                  />
                  <InputForm
                    controllerName={`experience.${index}.desc`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Deskripsi"}
                    placeholder={"Deskripsi"}
                    disabled={!isEdit}
                    textArea={true}
                  />

                  <div className="grid md:grid-cols-2 gap-4">
                    <AtomDatePicker
                      className="w-full rounded-md border-gray-300 border-solid text-base"
                      controllerName={`experience.${index}.year`}
                      label={"Tahun Pelaksanaan"}
                      labelClass="mb-2"
                      placeholder="Select a date"
                      format={"MM/yyyy"}
                      isRangeDatePicker
                      disabled={!isEdit}
                      showMonthYearPicker
                    />

                    <InputForm
                      controllerName={`experience.${index}.execution_duration`}
                      className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Durasi Pelaksanaan"}
                      placeholder={"Durasi Pelaksanaan"}
                      disabled={!isEdit}
                    />
                  </div>
                  <InputForm
                    controllerName={`experience.${index}.donor_name`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Nama Donor"}
                    placeholder={"Nama Donor"}
                    disabled={!isEdit}
                  />
                  <InputForm
                    controllerName={`experience.${index}.contract_value`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Nilai Kontrak"}
                    placeholder={"Nilai Kontrak"}
                    disabled={!isEdit}
                  />
                  <InputFormRadio
                    controllerName={`experience.${index}.is_audit`}
                    className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={
                      "Di Audit atau Tidak oleh Kantor Akuntan Publik (KAP)"
                    }
                    placeholder={
                      "Di Audit atau Tidak oleh Kantor Akuntan Publik (KAP)"
                    }
                    disabled={!isEdit}
                    values={[
                      {
                        label: "Ya",
                        value: true,
                      },
                      {
                        label: "Tidak",
                        value: false,
                      },
                    ]}
                  />
                  {getValues(`experience.${index}.is_audit`) === true && (
                    <>
                      <InputForm
                        controllerName={`experience.${index}.auditor`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={"KAP yang Mengaudit"}
                        placeholder={"KAP yang Mengaudit"}
                        disabled={!isEdit}
                      />
                      <InputForm
                        controllerName={`experience.${index}.result_audit`}
                        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                        label={"Hasil Penilaian Audit"}
                        placeholder={"Hasil Penilaian Audit"}
                        disabled={!isEdit}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {/* button Verification */}
          <RadioVerificationBadge
            className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
            name={"status_approval_programme"}
            nameNotes={"notes_approval_programme"}
            isEditVerification={isEditVerification}
            toogleIsEditVerification={toogleIsEditVerification}
            errors={errors?.status_approval_programme ? errors?.status_approval_programme : false}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PengalamanPengelolaanProgramChild;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { RiFile2Line, RiArrowLeftLine } from 'react-icons/ri';
import { CiCircleRemove, CiCircleCheck } from 'react-icons/ci';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';

import { Breadcrumbs, Card, CardBody, CardHeader, Button } from 'components';
import axiosInstance from 'app/interceptors';
import config from 'app/config';
import { ConfirmationModal } from 'components/molecules/Modal';
import { RejectModal } from 'components/molecules/Modal';
import { SuccessToast, ToastContext } from 'components/atoms/Toast';
import { getUrlFile } from 'services/danaProgram/fileService';
import {
  approvalEligiblityConceptNote,
  approvalConceptNote,
} from 'services/danaProgram/consepNoteService';

const validationSchema = yup.object().shape({});

const DetailConsepNote = () => {
  // constants
  const links = [
    {
      label: 'Daftar Permohonan Konsep Note',
    },
    {
      label: 'Detail',
    },
  ];

  // hooks
  let { id } = useParams();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reason_rejected: '',
      notes_result_review: '',
    },
  });

  // data
  const [data, setData] = useState();
  const [isApproverVerified, setIsApproverVerified] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectModals, setRejectModal] = useState(false);
  const [status, setStatus] = useState('');
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
  } = useContext(ToastContext);

  // template
  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onClose={() => setConfirmModal(!confirmModal)}
        onSubmit={() => {
          handleSubmit('APPROVED');
          setStatus('APPROVED');
        }}
      >
        Apakah Anda yakin akan menyetujui Permohonan Konsep Note ini?
      </ConfirmationModal>
    );
  };

  const renderReject = () => {
    return (
      <RejectModal
        title={'Tolak Konsep Note'}
        open={rejectModals}
        onClose={() => setRejectModal(!RejectModal)}
        onSubmit={() => {
          handleSubmit('REJECTED');
          setStatus('REJECTED');
        }}
        disabledOnSubmit={methods.watch('reason_rejected') ? false : true}
      >
        <div className="flex flex-col">
          <label className="text-gray-800 font-semibold text-sm">
            Alasan
            <span className="ml-1 text-error-500 text-sm">*</span>
          </label>
          <textarea
            {...methods.register('reason_rejected')}
            className="w-[600px] mt-2 rounded text-sm"
          />
        </div>
      </RejectModal>
    );
  };

  // requests
  const {
    isFetching: loadingVerifyApproval,
    refetch: verifyApproval,
    data: approvalVerified,
  } = useQuery(['verify-approval-', id], approvalEligiblityConceptNote, {
    enabled: false,
  });

  const submitApproval = useMutation({
    mutationFn: (payload) =>
      approvalConceptNote({ id: id, status: status }, payload),
  });

  // methods
  const handleSubmit = async (status) => {
    try {
      const payload = methods.getValues();

      submitApproval.mutate({
        ...payload,
      });

      enqueueSnackbar(`Concept Notes ${status}`, { variant: 'success' });

      navigate('/concept-notes');
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDetailConsep = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/notes/detail/${id}`
    );
    setData(res.data.data);
  };

  // cycles
  useEffect(() => {
    fetchDetailConsep();
    verifyApproval();
  }, []);

  useEffect(() => {
    console.log({approvalVerified});
    if (approvalVerified) {
      setIsApproverVerified(approvalVerified.data.message === 'Success');
    }
  }, [approvalVerified]);

  return (
    <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[81vw]">
      {showToast.success &&
        createPortal(
          <SuccessToast
            onClose={() => setShowToast(initialShowToast)}
            message={toastMessage}
          />,
          document.body,
          'success-confirm-note-concept'
        )}
      {renderModal()}
      {renderReject()}
      <Breadcrumbs items={links} />
      <div className="flex flex-col gap-4 mt-5">
        <Card>
          <CardHeader>
            <span className="text-xl">Detail</span>
          </CardHeader>
          <CardBody>
            <div className="flex flex-row">
              <div className="flex flex-col col-span-2 w-1/2">
                <span className="font-semibold">Proyek Yang diajukan</span>
                <span>{data?.project_name}</span>
              </div>
              <div className="flex flex-col col-span-2 w-1/2">
                {data?.status === 'Menunggu Persetujuan' ? (
                  <div> </div>
                ) : (
                  <>
                    <span className="font-semibold">Status</span>
                    <span>{data?.status}</span>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col mt-6">
                <span className="font-semibold">Outcome</span>
                <ul className="list-disc ml-4">
                  {data?.outcome?.map((outcome, index) => (
                    <li key={index}>{outcome.label}</li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col mt-6">
                <span className="font-semibold">Output</span>
                <ul className="list-disc ml-4">
                  {data?.output?.map((output, index) => (
                    <li key={index}>{output.label}</li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col mt-6">
                <span className="font-semibold">Judul Proposal</span>
                <span>{data?.proposal_title}</span>
              </div>
              <div className="flex flex-col mt-6">
                <span className="font-semibold">Deskripsi Proposal</span>
                <span>{data?.description_proposal}</span>
              </div>
              <div className="flex flex-col mt-6">
                <span className="font-semibold">Lokasi Kegiatan</span>
                <ul className="list-disc ml-4">
                  {data?.location?.map((loc, index) => (
                    <li key={index}>
                      {`${loc?.village?.label}, ${loc?.district?.label}, ${loc?.cities?.label}, ${loc?.province?.label}`}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-6">
              <div className="flex flex-col">
                <span className="font-semibold">Tanggal Pengajuan</span>
                <span>
                  {moment(data?.date_of_filling).format('DD-MM-YYYY')}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">
                  Lokasi kegiatan berada diwilayah konversi
                </span>
                {data?.is_conversion_area === true ? (
                  <>
                    <span>Ya</span>
                    <span>{data?.location_conversion_area}</span>
                  </>
                ) : (
                  <span>Tidak</span>
                )}
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">
                  Kegiatan ini melibatkan masyarakat adat
                </span>
                {data?.is_involve_indigenous_comunities === true ? (
                  <>
                    <span>Ya</span>
                    <span>{data?.comunities_name}</span>
                  </>
                ) : (
                  <span>Tidak</span>
                )}
                <span></span>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-6">
              <div className="flex flex-col">
                <span className="font-semibold">
                  Jumlah beneficiaries perempuan
                </span>
                <span>{data?.total_beneficiaries.female}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">
                  Jumlah beneficiaries laki -laki
                </span>
                <span>{data?.total_beneficiaries.male}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">Total Beneficiaries</span>
                <span>{data?.total_beneficiaries.total}</span>
              </div>
            </div>
            <div className="mt-6">
              {data?.file_beneficiaries_document?.map((e) => {
                return (
                  <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                    <div className="flex gap-4">
                      <div>
                        <div className="bg-[#EEFFF4] rounded-full p-4">
                          <RiFile2Line className="text-primary-600" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="font-medium">{e.fileName}</span>
                        <span>{`${Number(e.fileSize).toFixed(2)} MB`}</span>
                        <span
                          className="font-semibold text-primary-600 cursor-pointer"
                          onClick={async (event) => {
                            if (data?.status !== 'Ditolak') {
                              const res = await getUrlFile({
                                identifier: 'id',
                                value: e.fileId,
                              });
                              window.open(res?.data?.data?.url, '_blank');
                            } else {
                              event.preventDefault();
                            }
                          }}
                        >
                          Lihat Dokumen
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }) ?? null}
            </div>
            <div className="flex flex-col mt-6">
              <span className="font-semibold">
                Safeguard yang akan diterapkan
              </span>
              {data?.safeGuards?.map((el) => {
                return (
                  <ul className="ml-5">
                    <li className="list-disc">{el?.value}</li>
                  </ul>
                );
              }) ?? null}
            </div>
            <div className="mt-6">
              {data?.rab_documents?.map((e) => {
                return (
                  <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                    <div className="flex gap-4">
                      <div>
                        <div className="bg-[#EEFFF4] rounded-full p-4">
                          <RiFile2Line className="text-primary-600" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="font-medium">{e.fileName}</span>
                        <span>{`${Number(e.fileSize).toFixed(2)} MB`}</span>
                        <span
                          className="font-semibold text-primary-600 cursor-pointer"
                          onClick={async (event) => {
                            if (data?.status !== 'Ditolak') {
                              const res = await getUrlFile({
                                identifier: 'id',
                                value: e.fileId,
                              });
                              window.open(res?.data?.data?.url, '_blank');
                            } else {
                              event.preventDefault();
                            }
                          }}
                        >
                          Lihat Dokumen
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }) ?? null}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <span>Catatan Hasil Review</span>
          </CardHeader>
          <CardBody>
            {/* <span>{ data?.notes_result_review }</span> */}
            <textarea
              {...methods.register('notes_result_review')}
              className="w-full mt-2 rounded text-sm"
            />
          </CardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate('/concept-notes')}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            disabled={loadingVerifyApproval || !isApproverVerified}
            onClick={() => {
              setRejectModal(true);
              setStatus('REJECTED');
            }}
          >
            <CiCircleRemove />
            Tolak
          </Button>

          <Button
            type="button"
            disabled={loadingVerifyApproval || !isApproverVerified}
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => {
              setConfirmModal(true);
              setStatus('APPROVED');
            }}
          >
            <CiCircleCheck />
            Setuju
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DetailConsepNote;

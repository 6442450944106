import { useNavigate } from "react-router";
import SafeguardsFormChild from "./Form";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useMutation } from "@tanstack/react-query";
import {
  getFinancialProfile,
  getProgramSafeguards,
} from "services/danaProgram/danaProgramService";
import { BiCheck } from "react-icons/bi";

const FieldLabel = {
  general: {
    TAB2_VERIFICATION: "Hasil Verifikasi Tab Peralatan/ Perlengkapan",
    WRONG_DATA_DESCRIPTION: "Deskripsi Ketidaksesuaian Data",
  },
  info: {},
};

const SafeguardsForm = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  submitData,
}) => {
  const validationSchema = useMemo(() =>
    Yup.object().shape({
      tab2Verification: Yup.boolean()
        .required()
        .label(FieldLabel.general.TAB2_VERIFICATION),
      wrongDataDescription: Yup.string().label(
        FieldLabel.general.WRONG_DATA_DESCRIPTION
      ),
      safeguards: Yup.array().of(
        Yup.object().shape({
          status_approval_safeguard_data: Yup.string().required('Hasil verifikasi wajib diisi'),
          notes_approval_safeguard_data: Yup.string().nullable(true),
        })
      ),
    })
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      tab2Verification: false,
      safeguards: [{}],
    },
  });

  const { control, trigger } = { ...methods };

  const {
    fields: fieldsSafeguards,
    append: appendsSafeguards,
    remove: removeSafeguards,
  } = useFieldArray({
    control,
    name: "safeguards",
  });

  useEffect(() => {
    fieldsSafeguards?.map((index) => removeSafeguards(index));
    data?.data_safeguard?.map((field, index) => {
      appendsSafeguards({
        desc: field.desc,
        name: field.name,
        period: field.period,
        report: field.report,
        status_approval_safeguard_data:
        data?.status_approval_safeguard_data ? data?.status_approval_safeguard_data[index] : false,
        notes_approval_safeguard_data:
        data?.notes_approval_safeguard_data ? data?.notes_approval_safeguard_data[index] : false,
      });
    });
    trigger()
  }, [data]);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <SafeguardsFormChild
          data={data}
          safeguards={fieldsSafeguards}
          methods={methods}
        />

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                if (isValid){
                  const formData = methods.getValues();
                  submitData(formData);
                }
              }}
            >
              <BiCheck className="text-[20px] text-[#FFFFFF]" />
              Verifikasi Selesai
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default SafeguardsForm;
